import React from "react"
import styled from "styled-components"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import { filterCompleted } from "../utils"

const Wrapper = styled.div`
  .header {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;

    h3 {
      font-size: 18px;
      line-height: 18px;
      font-weight: 500;
      margin: 0;
    }

    p {
      font-size: 14px;
      line-height: 14px;
      font-weight: 400;
      margin: 0;
      margin-left: 10px;
    }
  }

  .swiper-container {
    max-width: 100vw;
    margin-left: -${({ theme }) => theme.margins.sm};
    margin-right: -${({ theme }) => theme.margins.sm};
    left: 0;

    .swiper-slide {
      width: 280px;
      padding-bottom: 20px;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    .swiper-container {
      width: calc(100vw - 300px);
      margin-left: -${({ theme }) => theme.margins.md};
      margin-right: -${({ theme }) => theme.margins.md};

      .swiper-slide {
        width: 310px;
      }
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    .swiper-container {
      margin-left: -${({ theme }) => theme.margins.lg};
      margin-right: -${({ theme }) => theme.margins.lg};
    }
  }
`

const GuideCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: ${({ theme }) => theme.palette.white};
  border-radius: 8px;
  padding: 10px;
  border: none;
  box-shadow: 0px 8px 30px -20px rgba(0, 0, 0, 0.5);

  &:hover {
    cursor: pointer;
  }

  &.viewed {
    border: 1px solid ${({ theme }) => theme.palette.conch};
    background-color: ${({ theme }) => theme.palette.mystic};
    box-shadow: none;

    .file-details {
      padding: 0px 0px 10px 10px;
    }
  }

  .check-icon {
    margin: 0;
    color: ${({ theme }) => theme.palette.aquaDeep};
  }

  .file-details {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px 0px 10px 10px;

    .text-wrapper {
      margin-left: 15px;
      margin-bottom: 0px;

      h4 {
        font-size: 14px;
        line-height: 14px;
        font-weight: 500;
        margin-bottom: 5px;
      }

      p {
        font-size: 11px;
        line-height: 11px;
        font-weight: 400;
        margin-bottom: 0px;
      }
    }
  }
`

interface Props {
  guides: Training.Guide[]
  onClick: (url: string, uuid: string) => void
}

const GuideSlider: React.FC<Props> = ({ guides, onClick }) => {
  const completed = filterCompleted(guides)

  return (
    <Wrapper>
      <div className="header">
        <h3>Guides</h3>
        <p>
          {completed.length}/{guides.length} viewed
        </p>
      </div>
      <div className="swiper-container">
        <Swiper
          slidesPerView={"auto"}
          slidesOffsetBefore={30}
          spaceBetween={20}
          slidesOffsetAfter={30}
          grabCursor={true}
          breakpoints={{
            1200: {
              slidesOffsetBefore: 65,
              slidesOffsetAfter: 65,
            },
          }}
        >
          {guides.map((guide) => (
            <SwiperSlide key={guide._uid}>
              <GuideCard
                className={guide.isCompleted ? "viewed" : undefined}
                onClick={() => {
                  onClick(guide.linkToAsset.filename, guide._uid)
                }}
              >
                {guide.isCompleted ? (
                  <svg className="check-icon" width={18} height={18}>
                    <use href="/images/icons/icon-circled-check.svg#base" />
                  </svg>
                ) : null}
                <div className="file-details">
                  <img src="/images/icons/icon-pdf.svg" alt="Pdf icon" />
                  <div className="text-wrapper">
                    <h4>{guide.title}</h4>
                    <p>
                      {guide.linkToAsset.filename.substring(
                        guide.linkToAsset.filename.lastIndexOf(".") + 1
                      )}
                    </p>
                  </div>
                </div>
              </GuideCard>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Wrapper>
  )
}

export default GuideSlider
