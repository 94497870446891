import { useMutation, useQuery } from "@apollo/client"
import { graphql, useStaticQuery } from "gatsby"
import { useCallback, useMemo } from "react"

import { useNotify } from "../alert/hooks"
import { useUser } from "../auth/hooks"
import { COMPLETE_TRAINING_MATERIAL } from "./mutations"
import { GET_TRAINING_PROGRESS } from "./queries"

interface CompleteTrainingPayload {
  materialId: string
  moduleId: string
}

export const useCompleteTrainingMaterial = (): ((
  payload: CompleteTrainingPayload
) => Promise<void>) => {
  const notify = useNotify()
  const [completeTrainingMaterialMutation] = useMutation(
    COMPLETE_TRAINING_MATERIAL,
    {
      context: {
        clientName: "private-api",
      },
    }
  )

  const completeTrainingMaterial = useCallback(
    async (payload: CompleteTrainingPayload) => {
      try {
        const { data } = await completeTrainingMaterialMutation({
          variables: {
            input: payload,
          },
        })
        if (
          data.completeTrainingMaterial.__typename ===
          "CompleteTrainingMaterialSuccess"
        ) {
          notify("success", "Completed training material")
        } else if (
          data.completeTrainingMaterial.__typename ===
          "TrainingMaterialAlreadyCompleted"
        ) {
          notify("info", "Material already completed")
        } else if (
          data.completeTrainingMaterial.__typename ===
          "TrainingMaterialDoesNotExist"
        ) {
          notify("warning", "Material not found")
        }
      } catch {
        notify("warning", "Training could not be completed", "please try again")
      }
    },
    [completeTrainingMaterialMutation, notify]
  )

  return completeTrainingMaterial
}

export const useFilterProfileType = (): ((
  materials: Training.StoryBlokMaterial[]
) => Training.StoryBlokMaterial[]) => {
  const { user } = useUser()
  const filterProfileType = useCallback(
    (materials: Training.StoryBlokMaterial[]) => {
      return materials.filter((material) => {
        if (user?.profile?.profileType) {
          return material.userType.includes(user?.profile?.profileType)
        } else {
          return []
        }
      })
    },
    [user?.profile?.profileType]
  )
  return filterProfileType
}

export const useTrainingModules = (): Training.Module[] => {
  const filterProfileType = useFilterProfileType()
  const trainingData: Storyblok.StoryBlokData = useStaticQuery(graphql`
    query trainingModules {
      allStoryblokEntry(
        filter: { full_slug: { glob: "training/*" } }
        sort: { fields: id }
      ) {
        edges {
          node {
            uuid
            name
            slug
            content
          }
        }
      }
    }
  `)

  const modulesData: Training.Module[] = useMemo(
    () =>
      trainingData.allStoryblokEntry.edges.map((module) =>
        JSON.parse(module.node.content)
      ),
    [trainingData.allStoryblokEntry.edges]
  )

  const filteredModulesData = modulesData.filter(
    (module) =>
      filterProfileType(module.videos).length > 0 ||
      filterProfileType(module.guides).length > 0
  )

  return filteredModulesData
}

export const useTrainingProgress = (): {
  trainingProgressData: Training.TrainingProgress
  loading: boolean
  refetch: () => void
} => {
  const { data, loading, refetch } = useQuery(GET_TRAINING_PROGRESS, {
    context: {
      clientName: "private-api",
    },
  })
  return {
    trainingProgressData: data?.trainingProgress,
    loading: loading,
    refetch: refetch,
  }
}
