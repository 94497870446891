import React from "react"

import { Chip } from "../../components"

type Props = {
  status: "complete" | "incomplete"
}

const StatusChip: React.FC<Props> = ({ status }: Props) => {
  return status === "complete" ? (
    <Chip
      size="small"
      backgroundColor="#000000"
      borderColor="#000000"
      textColor="#ffffff"
      text="Completed"
      iconPath="/images/icons/icon-check-white.svg"
      iconLeft={true}
    />
  ) : (
    <Chip
      size="small"
      backgroundColor="#ffffff"
      borderColor="#ffffff"
      text="Incomplete"
    />
  )
}

export default StatusChip
