import { gql } from "@apollo/client"
import { USER_DETAILS_FRAGMENT } from "../auth/fragments"
import { COMPLETED_TRAINING_FRAGMENT } from "./fragments"

export const COMPLETE_TRAINING_MATERIAL = gql`
  mutation completeTrainingMaterial(
    $input: CompleteTrainingMaterialMutationInput!
  ) {
    completeTrainingMaterial(input: $input) {
      ... on CompleteTrainingMaterialSuccess {
        me {
          ...userDetailsFragment
          ...completedTrainingFragment
          profile {
            trainingCompleted
          }
        }
      }
      ... on TrainingMaterialDoesNotExist {
        message
      }
      ... on TrainingMaterialAlreadyCompleted {
        message
      }
      ... on InternalServerError {
        message
      }
    }
  }
  ${USER_DETAILS_FRAGMENT}
  ${COMPLETED_TRAINING_FRAGMENT}
`
