import { Link } from "gatsby"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import styled from "styled-components"

import { useUser } from "../../auth/hooks"
import {
  Filter,
  Modal,
  PageGuard,
  PageHeader,
  PageNotificationBanner,
  VimeoPlayer,
} from "../../components"
import { FilterOption } from "../../components/Filter"
import Seo from "../../components/seo"
import GuideSlider from "../../training/components/GuideSlider"
import VideoSlider from "../../training/components/VideoSlider"
import {
  useCompleteTrainingMaterial,
  useFilterProfileType,
  useTrainingModules,
  useTrainingProgress,
} from "../../training/hooks"
import { filterCompleted, getTrainingMaterial } from "../../training/utils"

const CardGrid = styled.div`
  width: 100%;

  .module-slider {
    margin-bottom: 50px;
  }

  .video-slider {
    margin-bottom: 50px;
  }
`

const Training = (): React.ReactElement => {
  const { user } = useUser()
  const modulesData = useTrainingModules()
  const [modalShowing, setModalShowing] = useState(false)
  const [video, setVideo] = useState<Training.Video | null>()
  const [currentModuleIndex, setCurrentModuleIndex] = useState(0)
  const [trainingProgress, setTrainingProgress] = useState(0)
  const completeTrainingMaterial = useCompleteTrainingMaterial()
  const filterProfileType = useFilterProfileType()
  const { trainingProgressData, refetch } = useTrainingProgress()

  const handleCompleteTraining = useCallback(
    async (uuid: string) => {
      await completeTrainingMaterial({
        moduleId: modulesData[currentModuleIndex]._uid,
        materialId: uuid,
      })
      setModalShowing(false)

      const currentComplete = filterData?.[currentModuleIndex]?.completed ?? 0
      const quanitity = filterData?.[currentModuleIndex]?.quantity
      if (quanitity !== undefined && currentComplete + 1 >= quanitity) {
        setCurrentModuleIndex(
          Math.min(filterData.length - 1, firstIncompleteModule)
        )
      }

      // Refetch training progress
      refetch()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modulesData, currentModuleIndex, completeTrainingMaterial]
  )

  const filterData = useMemo((): FilterOption[] => {
    return modulesData.map((module, index) => ({
      title: module.title,
      quantity:
        (filterProfileType(module.videos)?.length ?? 0) +
        (filterProfileType(module.guides)?.length ?? 0),
      completed:
        filterCompleted(
          getTrainingMaterial(
            filterProfileType(modulesData[index].videos),
            user?.completedTrainingMaterial ?? []
          )
        ).length +
        filterCompleted(
          getTrainingMaterial(
            filterProfileType(modulesData[index].guides),
            user?.completedTrainingMaterial ?? []
          )
        ).length,
    }))
  }, [modulesData, user?.completedTrainingMaterial, filterProfileType])

  const firstIncompleteModule = useMemo(() => {
    const firstIncompleteIndex = filterData.findIndex(
      (data) => data.quantity !== data.completed
    )

    if (firstIncompleteIndex !== -1) {
      return firstIncompleteIndex
    } else {
      return 0
    }
  }, [filterData])

  const handleModuleChange = useCallback((targetModuleIndex: number) => {
    setCurrentModuleIndex(targetModuleIndex)
  }, [])

  const openVideoModal = (video: Training.Video) => {
    setVideo(video)
    setModalShowing(true)
  }

  const openGuideModal = useCallback(
    (url: string, uuid: string) => {
      handleCompleteTraining(uuid)
      window.open(url, "_blank")
    },
    [handleCompleteTraining]
  )

  useEffect(() => {
    setCurrentModuleIndex(
      Math.min(filterData.length - 1, firstIncompleteModule)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstIncompleteModule])

  useMemo(() => {
    setTrainingProgress(trainingProgressData?.trainingProgress ?? 0)
  }, [trainingProgressData?.trainingProgress])

  const guides = getTrainingMaterial(
    filterProfileType(modulesData?.[currentModuleIndex]?.guides ?? []),
    user?.completedTrainingMaterial ?? []
  )
  const videos = getTrainingMaterial(
    filterProfileType(modulesData?.[currentModuleIndex]?.videos ?? []),
    user?.completedTrainingMaterial ?? []
  )

  return (
    <PageGuard>
      <Seo title="Training" />
      <Modal open={modalShowing} onClose={() => setModalShowing(false)}>
        {video ? (
          <VimeoPlayer video={video} onVideoComplete={handleCompleteTraining} />
        ) : null}
      </Modal>
      <CardGrid>
        <PageHeader cypressTestId="training-page-header" title="Training" />
        <PageNotificationBanner>
          {trainingProgress < 100 ? (
            <p>
              You have to complete all available training modules to become a
              certified Yoco reseller. You have completed{" "}
              <span className="highlighted">{trainingProgress}%</span> of the
              training, keep going to become an official Yoco reseller.
            </p>
          ) : (
            <p>
              Congrats! You’ve completed all the training modules. Go back to{" "}
              <Link className="highlighted" to="/dashboard/">
                your dashboard
              </Link>
            </p>
          )}
        </PageNotificationBanner>
        <div className="module-slider">
          <Filter
            isPageFilter
            filterOptions={filterData}
            onClick={handleModuleChange}
            activeIndex={currentModuleIndex}
          />
        </div>
        {videos.length !== 0 ? (
          <div className="video-slider">
            <VideoSlider
              key={`module-${currentModuleIndex}`}
              moduleTitle={modulesData[currentModuleIndex].title}
              videos={videos as Training.Video[]}
              onClick={openVideoModal}
            />
          </div>
        ) : null}
        {guides.length !== 0 ? (
          <div className="guide-slider">
            <GuideSlider
              key={`module-${currentModuleIndex}`}
              guides={guides as Training.Guide[]}
              onClick={openGuideModal}
            />
          </div>
        ) : null}
      </CardGrid>
    </PageGuard>
  )
}

export default Training
