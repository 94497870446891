import React from "react"
import styled from "styled-components"
import "swiper/css"
import StatusChip from "./StatusChip"
import { Card } from "../../components"

const VideoCard = styled.div`
  height: 155px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-end;
  padding: 10px;

  * {
    z-index: 2;
    position: relative;
  }

  .playIcon {
    align-self: center;
    margin-top: 40px;
    background-color: #05222222;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 0.2s;

    img {
      margin: 0;
      margin-left: 2px;
      width: 8px;
      transition-property: width;
      transition-duration: 0.2s;
    }
  }
`

const Thumbnail = styled.div`
  background-color: ${({ theme }) => theme.palette.mineShaft};
  position: absolute;
  overflow: hidden;
  opacity: 1;
  transition: 0.2s;

  img {
    object-fit: cover;
    object-position: center;
  }
`

const VideoSlideContent = styled.div`
  margin-top: 2px;

  &:hover {
    cursor: pointer;

    ${VideoCard} {
      .playIcon {
        background-color: #222222;
        margin-top: 35px;
        height: 40px;
        width: 40px;

        img {
          width: 12px;
        }
      }
    }

    ${Thumbnail} {
      opacity: 0.6;
    }
  }

  .videoInfo {
    display: flex;
    align-items: flex-start;
    margin-top: 15px;

    h5 {
      font-size: 12px;
      line-height: 12px;
      font-weight: 400;
      margin: 0;
    }

    p {
      width: max-content;
      white-space: nowrap;
      font-size: 11px;
      line-height: 11px;
      font-weight: 400;
      margin: 0;
      margin-left: 10px;
    }
  }
`

interface Props {
  video: Training.Video
  onClick: (video: Training.Video) => void
}

const VideoSlide: React.FC<Props> = ({ video, onClick }) => {
  const thumbnail = video.thumbnail

  return (
    <VideoSlideContent
      onClick={() => {
        onClick(video)
      }}
    >
      <Card backgroundColor="transparent" padding="0px">
        <Thumbnail>
          <img
            src={`${thumbnail.filename}/m/620x0`}
            alt={thumbnail.alt ? thumbnail.alt : "Thumbnail"}
          />
        </Thumbnail>
        <VideoCard>
          <StatusChip status={video.isCompleted ? "complete" : "incomplete"} />
          <div className="playIcon">
            <img
              src="/images/icons/icon-play-arrow-white.svg"
              alt="Play button icon"
            />
          </div>
        </VideoCard>
      </Card>
      <div className="videoInfo">
        <h5>{video.title}</h5>
        <p>{video.estimatedLength} min</p>
      </div>
    </VideoSlideContent>
  )
}

export default VideoSlide
