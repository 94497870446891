import React from "react"
import styled from "styled-components"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import { filterCompleted } from "../utils"
import VideoSlide from "./VideoSlide"

const Wrapper = styled.div`
  .header {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;

    h3 {
      font-size: 16px;
      line-height: 18px;
      font-weight: 500;
      margin: 0;
    }

    p {
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      margin: 0;
      margin-left: 10px;
    }
  }

  .swiper-container {
    max-width: 100vw;
    margin-left: -${({ theme }) => theme.margins.sm};
    margin-right: -${({ theme }) => theme.margins.sm};

    .swiper-slide {
      height: 215px;
      width: 310px;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      width: calc(100vw - 300px);
      margin-left: -${({ theme }) => theme.margins.md};
      margin-right: -${({ theme }) => theme.margins.md};
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      width: calc(100vw - 300px);
      margin-left: -${({ theme }) => theme.margins.lg};
      margin-right: -${({ theme }) => theme.margins.lg};
    }
  }
`

interface Props {
  moduleTitle: string
  videos: Training.Video[]
  onClick: (video: Training.Video) => void
}

const VideoSlider: React.FC<Props> = ({ moduleTitle, videos, onClick }) => {
  const completed = filterCompleted(videos)

  return (
    <Wrapper>
      <div className="header">
        <h3>{moduleTitle} videos</h3>
        <p>
          {completed.length}/{videos.length} completed
        </p>
      </div>
      <div className="swiper-container">
        <Swiper
          slidesPerView={"auto"}
          slidesOffsetBefore={30}
          spaceBetween={20}
          slidesOffsetAfter={30}
          grabCursor={true}
          breakpoints={{
            1200: {
              slidesOffsetBefore: 65,
              slidesOffsetAfter: 65,
            },
          }}
        >
          {videos.map((video) => (
            <SwiperSlide key={video._uid}>
              <VideoSlide video={video} onClick={onClick} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Wrapper>
  )
}

export default VideoSlider
