export const getTrainingMaterial = (
  materials: Training.StoryBlokMaterial[],
  completedMaterial: Training.CompletedMaterial[]
): Training.Material[] => {
  return (
    materials?.map((material: Training.StoryBlokMaterial) => {
      const isCompleted = completedMaterial.some(
        (instance) => instance.material.id === material._uid
      )
      return { isCompleted: isCompleted, ...material }
    }) ?? []
  )
}

export const filterCompleted = (
  materials: Training.Material[]
): Training.Material[] => {
  return materials.filter((material) => material.isCompleted === true)
}
